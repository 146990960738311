import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "modal entry-modal",
  role: "dialog",
  "aria-live": "assertive"
}
const _hoisted_2 = { class: "modal-headline modal-headline-2" }
const _hoisted_3 = { class: "entry-details-main" }
const _hoisted_4 = { class: "entry-details modal-content ice" }
const _hoisted_5 = { class: "large-only" }
const _hoisted_6 = { class: "entry-modal-reactions" }
const _hoisted_7 = { class: "entry-modal-extras-headline upper" }
const _hoisted_8 = { class: "entry-modal-reactions-copy" }
const _hoisted_9 = { class: "entry-modal-earn-copy" }
const _hoisted_10 = { class: "entry-modal-share" }
const _hoisted_11 = ["data-url", "title", "aria-label"]
const _hoisted_12 = { class: "entry-details-submission" }
const _hoisted_13 = { class: "entry-details-story-by" }
const _hoisted_14 = { class: "entry-details-author" }
const _hoisted_15 = { class: "entry-details-submitted-date nowrap" }
const _hoisted_16 = { class: "small-only" }
const _hoisted_17 = { class: "entry-details-location" }
const _hoisted_18 = { class: "entry-details-location-name" }
const _hoisted_19 = { class: "entry-details-location-city-state" }
const _hoisted_20 = ["title", "aria-label", "innerHTML"]
const _hoisted_21 = {
  key: 0,
  class: "entry-details-essay"
}
const _hoisted_22 = { class: "entry-details-essay-question" }
const _hoisted_23 = ["innerHTML"]
const _hoisted_24 = {
  key: 1,
  class: "entry-details-media-container"
}
const _hoisted_25 = { class: "photo-wrapper" }
const _hoisted_26 = ["src", "alt"]
const _hoisted_27 = {
  key: 2,
  class: "entry-details-media-container"
}
const _hoisted_28 = { class: "video-wrapper" }
const _hoisted_29 = ["src"]
const _hoisted_30 = { class: "entry-modal-footer modal-content ice small-only" }
const _hoisted_31 = { class: "entry-modal-footer-reactions" }
const _hoisted_32 = { class: "entry-modal-extras-headline upper" }
const _hoisted_33 = { class: "entry-modal-reactions-copy" }
const _hoisted_34 = { class: "entry-modal-share" }
const _hoisted_35 = ["data-url", "title", "aria-label"]
const _hoisted_36 = ["title", "aria-label", "innerHTML"]
const _hoisted_37 = ["title", "aria-label"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_EntryReactions = _resolveComponent("EntryReactions")
  const _component_VideoPlayer = _resolveComponent("VideoPlayer")

  return (_openBlock(), _createElementBlock("div", {
    class: "modal-overlay",
    onClick: _cache[7] || (_cache[7] = _withModifiers($event => (_ctx.closeModal()), ["self"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("h2", null, _toDisplayString($data.communityName), 1)
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("h5", _hoisted_7, _toDisplayString($setup.t('reactions')), 1),
              _createVNode(_component_EntryReactions, {
                "entry-id": $props.entry.ce.id,
                "facility-id": $props.entry.f.data.id
              }, null, 8, ["entry-id", "facility-id"]),
              _createElementVNode("p", _hoisted_8, _toDisplayString($options.reactionsDirections), 1),
              _createElementVNode("p", _hoisted_9, _toDisplayString($setup.t('entry_modal:reactions_directions.earn_points')), 1)
            ]),
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("a", {
                class: "button entry-modal-button facebook_share",
                href: "#facebook_share",
                "data-e2e": "facebook-share-link",
                "data-url": _ctx.shareUrl,
                title: $setup.t('share:facebook.link_title'),
                "aria-label": $setup.t('share:facebook.link_title'),
                onClick: _cache[0] || (_cache[0] = $event => ($options.trackUrchinShare('share', 'footer-facebook')))
              }, [
                _cache[8] || (_cache[8] = _createElementVNode("i", { class: "fab fa-facebook entry-modal-fab" }, null, -1)),
                _createTextVNode(" " + _toDisplayString($setup.t('facebook')), 1)
              ], 8, _hoisted_11)
            ])
          ]),
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("div", _hoisted_13, _toDisplayString($data.byLine), 1),
            _createElementVNode("div", _hoisted_14, _toDisplayString($data.author) + ". ", 1),
            _createElementVNode("div", _hoisted_15, [
              _createElementVNode("span", _hoisted_16, _toDisplayString($setup.t('submitted')), 1),
              _createTextVNode(" " + _toDisplayString($data.dateSubmitted), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_17, [
            _createElementVNode("div", _hoisted_18, _toDisplayString($data.communityName), 1),
            _createElementVNode("div", _hoisted_19, _toDisplayString($data.city) + ", " + _toDisplayString($data.province), 1),
            _createElementVNode("a", {
              href: "#",
              class: "entry-modal-cta upper large-only",
              title: $setup.t('cta'),
              "aria-label": $setup.t('cta'),
              onClick: [
                _cache[1] || (_cache[1] = $event => ($options.trackUrchinShare('entry-modal', 'cta'))),
                _cache[2] || (_cache[2] = _withModifiers($event => ($options.goToFacility($data.facility)), ["prevent"]))
              ],
              innerHTML: $setup.t('cta')
            }, null, 8, _hoisted_20)
          ])
        ]),
        ($data.entryType === 'story')
          ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.essays, (essay, index) => {
                return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                  _createElementVNode("div", _hoisted_22, [
                    _createElementVNode("strong", null, _toDisplayString(essay.question), 1)
                  ]),
                  _createElementVNode("div", {
                    class: "entry-details-essay-answer",
                    innerHTML: $options.essayTranslations(essay)
                  }, null, 8, _hoisted_23)
                ], 64))
              }), 128))
            ]))
          : _createCommentVNode("", true),
        ($data.entryType === 'photo')
          ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
              _createElementVNode("div", _hoisted_25, [
                _createElementVNode("img", {
                  class: "entry-details-photo",
                  src: $data.photoSource,
                  alt: $setup.t('entry_modal:alt_text.photo')
                }, null, 8, _hoisted_26)
              ])
            ]))
          : _createCommentVNode("", true),
        ($data.entryType === 'video')
          ? (_openBlock(), _createElementBlock("div", _hoisted_27, [
              _createElementVNode("div", _hoisted_28, [
                ($data.youtubeSource)
                  ? (_openBlock(), _createElementBlock("iframe", {
                      key: 0,
                      class: "entry-modal-youtube-video",
                      src: $data.youtubeSource,
                      width: "560",
                      height: "315",
                      frameborder: "0",
                      allow: "accelerometer; encrypted-media; gyroscope; picture-in-picture",
                      allowfullscreen: "",
                      sandbox: "allow-same-origin allow-scripts"
                    }, null, 8, _hoisted_29))
                  : (_openBlock(), _createBlock(_component_VideoPlayer, {
                      key: 1,
                      class: "entry-modal-video",
                      options: $options.videoOptions($data.videoSource)
                    }, null, 8, ["options"]))
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_30, [
        _createElementVNode("div", _hoisted_31, [
          _createElementVNode("h5", _hoisted_32, _toDisplayString($setup.t('reactions')), 1),
          _createVNode(_component_EntryReactions, {
            "entry-id": $props.entry.ce.id,
            "facility-id": $props.entry.f.data.id
          }, null, 8, ["entry-id", "facility-id"]),
          _createElementVNode("p", _hoisted_33, _toDisplayString($options.reactionsDirections), 1)
        ]),
        _createElementVNode("div", _hoisted_34, [
          _createElementVNode("a", {
            class: "button entry-modal-button facebook_share",
            href: "#facebook_share",
            "data-e2e": "facebook-share-link",
            "data-url": _ctx.shareUrl,
            title: $setup.t('share:facebook.link_title'),
            "aria-label": $setup.t('share:facebook.link_title'),
            onClick: _cache[3] || (_cache[3] = $event => ($options.trackUrchinShare('share', 'footer-facebook')))
          }, [
            _cache[9] || (_cache[9] = _createElementVNode("i", { class: "fab fa-facebook entry-modal-fab" }, null, -1)),
            _createTextVNode(" " + _toDisplayString($setup.t('facebook')), 1)
          ], 8, _hoisted_35)
        ]),
        _createElementVNode("a", {
          href: "#",
          class: "entry-modal-cta upper nowrap",
          title: $setup.t('cta'),
          "aria-label": $setup.t('cta'),
          onClick: [
            _cache[4] || (_cache[4] = $event => ($options.trackUrchinShare('entry-modal', 'cta'))),
            _cache[5] || (_cache[5] = _withModifiers($event => ($options.goToFacility($data.facility)), ["prevent"]))
          ],
          innerHTML: $setup.t('cta')
        }, null, 8, _hoisted_36)
      ]),
      _createElementVNode("a", {
        href: "#",
        class: "close-x",
        "data-e2e": "error-modal-close",
        title: $setup.t('modal_content:titles.close'),
        "aria-label": $setup.t('modal_content:titles.close'),
        onClick: _cache[6] || (_cache[6] = _withModifiers($event => (_ctx.closeModal()), ["prevent"]))
      }, _cache[10] || (_cache[10] = [
        _createElementVNode("i", { class: "fas fa-times" }, null, -1)
      ]), 8, _hoisted_37)
    ])
  ]))
}