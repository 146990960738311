import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["onClick"]
const _hoisted_2 = ["alt", "src", "onClick"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["reactions", { 'logged-out': !_ctx.profile.id, 'reaction-made': $data.currentReaction }])
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.reactions, (reaction, key) => {
      return (_openBlock(), _createElementBlock("div", {
        key: key,
        class: _normalizeClass(["reaction", { 'selected-reaction': $data.currentReaction === reaction }]),
        onClick: _withModifiers($event => ($options.submitReaction(reaction)), ["prevent"])
      }, [
        _createElementVNode("img", {
          alt: `${reaction}`,
          class: "reaction-icon",
          src: `../public/img/reactions/${reaction}.png`,
          onClick: $event => ($options.trackUrchinReaction('click', reaction))
        }, null, 8, _hoisted_2)
      ], 10, _hoisted_1))
    }), 128))
  ], 2))
}