
    import { useI18next } from '@composables/i18next';
    import { mapState } from 'vuex';
    import EntryReactions from '@components/EntryReactions.vue';
    import VideoPlayer from '@components/VideoPlayer.vue';
    import community from '../community';
    import { track } from '../plugins/WebAnalytics';
    import BaseModal from './BaseModal';

    export default {
        name: 'EntryModal',

        components: {
            EntryReactions,
            VideoPlayer,
        },

        extends: BaseModal,

        props: {
            entry: {
                type: Object,
                required: true,
            },
        },

        setup () {
            const { t, vT } = useI18next(['entry_modal', 'share', 'modal_content']);
            return { t, vT };
        },

        data () {
            return {
                author: '',
                byLine: '',
                city: '',
                communityName: '',
                dateSubmitted: '',
                entryType: '',
                essays: [],
                facility: {},
                modalName: 'entry',
                photoSource: '',
                province: '',
                videoSource: '',
                youtubeSource: '',
            };
        },

        computed: {
            ...mapState(['app']),

            getTwitterShareMessage () {
                return this.t(`share:community.${this.app.phase}.twitter.description`);
            },
            reactionsDirections () {
                return this.t(`entry_modal:reactions_directions.${this.entryType}`);
            },

            essayTranslations () {
                return essay => ((essay.translation !== '') ? essay.translation : essay.answer);
            },
        },

        created () {
            this.author = this.entry.ce.data.author;
            this.byLine = this.getByLineByType(this.entry.ce.type);
            this.city = this.entry.f.data.city;
            this.communityName = this.entry.f.data.name;
            this.dateSubmitted = this.getPrettyDate(this.entry.ce.created_at);
            this.entryType = this.entry.ce.type;
            this.facility = this.entry.f;
            this.province = this.entry.f.data.state;

            if (this.entry.ce.type === 'story') {
                this.essays.push({
                    question: this.t('entry_modal:question_1'),
                    answer: this.entry.ce.data.question1,
                    translation: this.t(`entry_modal:top_4_facilities.${this.entry.ce.id}.answer_1`),
                });
                this.essays.push({
                    question: this.t('entry_modal:question_2'),
                    answer: this.entry.ce.data.question2,
                    translation: this.t(`entry_modal:top_4_facilities.${this.entry.ce.id}.answer_2`),
                });
                this.essays.push({
                    question: this.t('entry_modal:question_3'),
                    answer: this.entry.ce.data.question3,
                    translation: this.t(`entry_modal:top_4_facilities.${this.entry.ce.id}.answer_3`),
                });
            }

            if (this.entry.ce.type === 'photo') {
                this.photoSource = this.entry.ce.data.photo;
            }

            if (this.entry.ce.type === 'video' && this.isAYoutubeVideo(this.entry.ce.data.video)) {
                this.youtubeSource = this.getYoutubeSource(this.entry.ce.data.video);
            }

            if (this.entry.ce.type === 'video' && !this.isAYoutubeVideo(this.entry.ce.data.video)) {
                this.videoSource = this.entry.ce.data.video;
            }

            this.createShareUrl();
        },

        methods: {
            createShareUrl () {
                this.shareUrl = `${this.app.siteURL}?locale=${this.app.userLanguage}#/community/${this.facility.data.id}/entry/${this.entry.ce.data.id}`;
            },

            getByLineByType (type) {
                type = (type === 'video') ? 'story' : type;
                return this.t(`${type}_by`);
            },

            getPrettyDate (time) {
                const dateObject = new Date(time);
                return `${dateObject.getMonth() + 1}.${dateObject.getDate()}.${dateObject.getFullYear() % 100}`;
            },

            getYoutubeId (url) {
                const regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
                const id_match = url.match(regExp);

                const v_id = (id_match && id_match[2].length == 11) ? id_match[2] : false;
                return v_id;
            },

            getYoutubeSource (url) {
                return `https://www.youtube.com/embed/${this.getYoutubeId(url)}?enablejsapi=1`;
            },

            goToFacility (facility) {
                this.closeModal();
                if (this.$router.currentRoute.path !== community.getFacilityPath(facility)) {
                    this.$router.push({ path: community.getFacilityPath(facility) });
                    try { window.fbq('track', 'AddToCart'); } // Facebook Pixel
                    catch (e) { return; }
                }
            },

            isAYoutubeVideo (url) {
                const youtubeLinkRegX = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=|\?v=)([^#&?]*).*/;
                return youtubeLinkRegX.test(url);
            },

            trackUrchinShare (action, category) {
                track(action, { category });
            },


            videoOptions (source) {
                return {
                    poster: `${source}/frame_0000.png`,
                    sources: [
                        {
                            src: source,
                            type: 'video/mp4',
                        },
                    ],
                };
            },
        },
    };
